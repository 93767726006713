export const isMobile = (): boolean => {
    return window.innerWidth < 768
};

export const truncateText = (text: string, max_length: number): { text: string, origin_text: string, truncated: boolean} => {
    if (!text) { return { text: '', origin_text: '', truncated: false }; }
    if (text.length > max_length) {
      return { 
        origin_text: text,
        text: text.substring(0, max_length) + '...',
        truncated: true
      };
   }
   return { text: text, origin_text: text, truncated: false };
}

export const hideTooltipById = (tooltipId: string) => {
  const tooltip = document.getElementById(tooltipId) as HTMLElement;
  hideTooltip(tooltip);
}

export const hideTooltip = (tooltip: HTMLElement) => {
  tooltip.style.visibility = 'hidden';
  tooltip.style.display = 'none';

  setTooltipClass(tooltip, `Tooltip-Tip right`);
}

export const showTooltipById = (tooltipId: string) => {
  const tooltip = getTooltipById(tooltipId);
  showTooltip(tooltip);
}

export const showTooltip = (tooltip: HTMLElement) => {
  tooltip.style.visibility = 'visible';
}

export const getTooltipById = (tooltipId: string) => {
  const tooltip = document.getElementById(tooltipId) as HTMLElement;
  return tooltip;
}

export const setTooltipClass = (tooltip: HTMLElement, className: string) => {
  tooltip.className = className;
}

export const setTooltipPosition = (tooltipId: string, defaultPosition: string): HTMLElement | null  => {
  let position = defaultPosition;

  const tableContainer = document.getElementById('car-catalog-table-container');

  if (!tableContainer) {
    return null;
  }
  const tableContainerPosition = tableContainer.getBoundingClientRect();
  
  const tooltip = getTooltipById(tooltipId);

  if (!tooltip) {
    return null;
  }

  tooltip.style.display = 'block';

  const startVisibleArea = tableContainerPosition.top;
  const endVisibleArea = tableContainerPosition.bottom - 50;

  const tooltipPosition = tooltip.getBoundingClientRect();

  const isTooltipTopInvisible = tooltipPosition.top < startVisibleArea;
  const isTooltipBottomInvisible = tooltipPosition.bottom > endVisibleArea;
  const isTooltipFullyVisible = !isTooltipTopInvisible && !isTooltipBottomInvisible;

  if (isTooltipTopInvisible) {
    position = 'bottom';
  }

  if (isTooltipBottomInvisible) {
    position = 'top';
  }

  if (isTooltipFullyVisible) {
    position = defaultPosition;
  }

  setTooltipClass(tooltip, `Tooltip-Tip ${position}`);

  return tooltip;
}

export const setCountClipsMenuPosition = (menuId: string, defaultHeight: number) => {
  const menu = document.getElementById(menuId) as HTMLElement;
  const bottomGap = 50;
  const topGap = 100;
  const minMenuHeight = 100;

  if (!menu) {
    return;
  }

  const tableContainer = document.getElementById('car-catalog-table-container');

  if (!tableContainer) {
    return;
  }

  // set initial style
  menu.style.top = '100%';
  menu.style.bottom = 'unset';
  menu.style.height = `${defaultHeight}px`;

  const tableContainerPosition = tableContainer.getBoundingClientRect();
  const startVisibleArea = tableContainerPosition.top;
  const endVisibleArea = tableContainerPosition.bottom - bottomGap;

  const menuPosition = menu.getBoundingClientRect();

  const isMenuTopInvisible = menuPosition.top < startVisibleArea;
  const isMenuBottomInvisible = menuPosition.bottom > endVisibleArea;
  const isTooltipFullyVisible = !isMenuTopInvisible && !isMenuBottomInvisible;
          
  if (isMenuBottomInvisible) {
      const heightToShow = menuPosition.height - (menuPosition.bottom - endVisibleArea);

      if (heightToShow > minMenuHeight) {
          menu.style.height = `${heightToShow}px`;
      } else {
          const height = menuPosition.top - startVisibleArea - topGap;
          
          menu.style.top = 'unset';
          menu.style.bottom = '100%';
          menu.style.height = height < defaultHeight ? `${height}px` : `${defaultHeight}px`;
      }
  }
}